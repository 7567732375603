import { AppEnvironment } from './environment.model';

export class Environment {
  private readonly _cmsEndpoint: string;
  private readonly _window: Window;
  private readonly _meiliEndpoint: string;

  constructor(env: AppEnvironment, window: Window) {
    switch (env.type) {
      case 'DomainBased': {
        const domain = env.domain.replace(/\/$/, '');
        this._cmsEndpoint = `https://cms.${domain}`;
        this._meiliEndpoint = `https://meilisearch.${domain}`;
        break;
      }
      case 'HostBased': {
        const { hostname, protocol } = window.location;
        const [, domain] = hostname.split(/^[^.]+?\./);
        this._cmsEndpoint = `${protocol}//cms.${domain}`;
        this._meiliEndpoint = `${protocol}//meilisearch.${domain}`;
        break;
      }
      case 'Full': {
        const { cmsEndpoint, meiliEndpoint } = env;
        this._cmsEndpoint = cmsEndpoint;
        this._meiliEndpoint = meiliEndpoint as string;
        break;
      }
      default: {
        throw 'Invalid environment';
      }
    }
    this._window = window;
  }

  get cmsEndpoint() {
    return this._cmsEndpoint;
  }

  get meiliEndpoint() {
    return this._meiliEndpoint;
  }

  get cmsApiEndpoint() {
    return `${this._cmsEndpoint}/api`;
  }

  get window() {
    return this._window;
  }
}
